<template>
  <div class="header-widget">
    <BackgroundPattern class="background-pattern" />
    <WavyDecoration class="wavy-decoration" />
    <h1 class="text">
      <span class="subtitle">{{ widget.subtitle }}</span>
      <span class="title title--first-line">{{ widget.title }}</span>
      <AnimatedText
        class="title title--second-line"
        :text="animatedText.first_line"
      />
      <AnimatedText
        class="title title--third-line"
        :text="animatedText.second_line"
      />
    </h1>

    <BaseImage
      class="widget-image widget-image--mobile md-down"
      :lazyload="lazyImage"
      :img-sizes="[{ w: 360, h: 414 }]"
      sizes="100vw"
      :image="widget.image_mobile"
    />
    <BaseImage
      class="widget-image widget-image--tablet lg-down md-up"
      :lazyload="lazyImage"
      :image="widget.image_tablet"
      sizes="100vw"
      :img-sizes="[{ w: 768, h: 559 }]"
    />
    <BaseImage
      class="widget-image widget-image--desktop lg-up"
      :lazyload="lazyImage"
      :image="widget.image_desktop"
      sizes="100vw"
      :img-sizes="[{ w: 1218, h: 762 }]"
    />

    <div class="bottom">
      <AirplaneTiles class="airplane-tiles" />
      <LinkBar :links="widget.bar_links" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import AirplaneTiles from '@/assets/decorations/airplane-tiles.svg?component'
import BackgroundPattern from '@/assets/decorations/background-pattern.svg?component'
import WavyDecoration from '@/assets/decorations/wavy-decoration.svg?component'
import type { PublicHeaderWidget } from '@/service/__generated-api'
import type { Widget } from '@/types/utils'

const props = defineProps<Widget<PublicHeaderWidget>>()

const animatedText = useIntervalArray(props.widget.animated_texts)
</script>

<style lang="scss" scoped>
.header-widget {
  position: relative;
  overflow: hidden;
}

.background-pattern {
  position: absolute;
  top: rem(148px);
  left: -15%;
  width: 133%;
  height: auto;

  @include media-up(md) {
    top: rem(231px);
    left: -3%;
    width: 114%;
    height: auto;
  }

  @include media-up(lg) {
    top: rem(101px);
    left: 0;
    width: 100%;
    height: auto;
  }
}

.wavy-decoration {
  position: absolute;
  top: rem(-25px);
  left: 0;
  width: 155%;
  height: auto;
  color: $dark-brown;
  filter: drop-shadow(0 8px 12px #00000033);

  @include media-up(md) {
    top: rem(-30px);
    width: 117%;
  }

  @include media-up(lg) {
    top: rem(-130px);
    width: 100%;
  }

  .contrast-mode & {
    color: $contrast-yellow;
  }
}

.text {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: rem(50px);
  color: $beige;
  text-align: center;
  text-transform: uppercase;

  @include media-up(md) {
    margin-top: rem(75px);
  }

  @include media-up(md) {
    margin-top: rem(82px);
  }

  @include media-up(xl) {
    margin-top: rem(172px);
  }

  .contrast-mode & {
    color: $contrast-black;
  }
}

.subtitle {
  @include font-size(
    (
      xs: 11px,
      md: 14px,
      lg: 18px,
      xl: 21px
    ),
    1.2
  );
  font-weight: 600;
}

.title {
  @include font-size(
    (
      xs: 52px,
      md: 76px,
      lg: 96px,
      xl: 136px
    ),
    1.2
  );
  font-weight: 945;
  color: $light-blue-1;
  letter-spacing: -0.025em;

  .contrast-mode & {
    color: $contrast-black;
  }

  &--first-line {
    margin-top: -0.1em;
    color: $beige;
  }

  &--second-line {
    margin-top: -0.38em;
  }

  &--third-line {
    @include font-size(
      (
        xs: 36px,
        md: 46px,
        lg: 58px
      )
    );
    height: rem(62px);
    margin-top: -0.76em;

    @include media-up(md) {
      height: rem(91px);
    }

    @include media-up(lg) {
      height: rem(115px);
    }

    @include media-up(xl) {
      margin-top: -1.3em;
    }
  }
}

.widget-image {
  position: relative;
  margin-top: rem(-33px);

  @include media-up(md) {
    margin-top: rem(-47px);
  }

  @include media-up(lg) {
    max-width: calc(100% - 148px);
    margin-top: rem(-280px);
    margin-left: rem(148px);

    :deep(img) {
      width: 100%;
    }
  }

  &--mobile {
    aspect-ratio: 360/414;
  }

  &--tablet {
    aspect-ratio: 768/559;
  }

  &--desktop {
    aspect-ratio: 1201/751;
  }
}

.bottom {
  position: absolute;
  bottom: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  width: 100%;

  @include media-up(lg) {
    flex-direction: row;
    align-items: flex-end;
  }
}

.airplane-tiles {
  width: 50%;
  height: 100%;
  aspect-ratio: 1/1;

  @include media-up(md) {
    width: 25%;
  }
}
</style>
